import { render, staticRenderFns } from "./optionItem.vue?vue&type=template&id=dd37c1e6&scoped=true&"
import script from "./optionItem.vue?vue&type=script&lang=js&"
export * from "./optionItem.vue?vue&type=script&lang=js&"
import style0 from "./optionItem.vue?vue&type=style&index=0&id=dd37c1e6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.2@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd37c1e6",
  null
  
)

export default component.exports
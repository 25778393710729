<template>
    <defaultSec :title="'< 返回'" :returnState="true">
        <div>
            <el-form :model="form" label-width="120px">
                <el-form-item label="id" prop="id" v-show="false">
                    <el-input v-model="form.id" placeholder="id"></el-input>
                </el-form-item>
                <el-form-item label="dicId" prop="dicId" :required="true">
                    <el-input v-model="form.dicId" placeholder="dicId"></el-input>
                </el-form-item>
                <el-form-item label="dicKey" prop="dicKey"  :required="true">
                    <el-input v-model="form.dicKey" placeholder="dicKey"></el-input>
                </el-form-item>
                <el-form-item label="dicValue" prop="dicValue" :required="true">
                    <el-input v-model="form.dicValue" placeholder="dicValue"></el-input>
                </el-form-item>
                <el-form-item label="parentDicKey" prop="parentDicKey" :required="true" >
                    <el-input v-model="form.parentDicKey" placeholder="parentDicKey"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="save" size="mini" class="header-btn">
                        保存
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
    </defaultSec>
</template>

<script>
    import defaultSec from '@c/defaultSection'
    import tablePagination from '@c/tablePagination'
    export default {
        data() {
            return {
                form: {
                    id: this.$route.params.id || 0,
                    dicId:this.$route.params.dicId,
                    dicKey:'',
                    dicValue:'',
                    parentDicKey:''
                },
                total: 0
            }
        },
        components: {
            defaultSec, tablePagination
        },
        mounted(){
            if(this.form.id>0){
                this.getDetail()
            }
        },
        methods: {
            getDetail() {
                this.$apiGet('lechun-cms/scrmOption/getDicDetail', {id: this.form.id}).then(res => {
                    this.form = res
                })
            },
            save(){
                if(this.form.dicKey==''){
                    this.$message({
                        showClose: true,
                        message: 'dicKey必填',
                        type: 'error'
                    });
                    return;
                }
                if(this.form.dicValue==''){
                    this.$message({
                        showClose: true,
                        message: 'dicValue必填',
                        type: 'error'
                    });
                    return;
                }
                if(this.form.dicId==''){
                    this.$message({
                        showClose: true,
                        message: 'dicId必选',
                        type: 'error'
                    });
                    return;
                }
                this.$apiGet('lechun-cms/scrmOption/saveDicDetail', this.form).then(res => {
                    console.log(res)
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    });
                    this.$router.push({
                        path: '/optionDetail/:id',
                        name: 'optionDetail',
                        params: {id: this.form.dicId }
                    });
                })
            }
        }
    }
</script>


<style scoped>
    .expand-title {
        width: 70px;
        color: #444548;
        line-height: 30px;
        text-align: right;
        margin-right: 10px;
        font-weight: 600;
    }

    .expand-flex {
        justify-content: flex-start;
        align-items: center;
        align-content: center;
    }
</style>